.Main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    padding-top: 10px;
  }
form {
    max-width: 800px;
    width: 75%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 100px);
    overflow: auto;
}
  
  label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  input[type="email"], input[type="password"],input[type="number"],input[type="tel"], input[type="password"], input[type="date"], select{
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
} 
  input[type="submit"] {
    background-color: var(--DarkLila);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  input[type="submit"]:hover {
    background-color: var(--DarkRed);
  }


.form-group {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.form-group label{
  width: 50%;
}

@media screen and (max-width: 600px) {
    .form-group {
        flex-direction: column;
    }
    .form-group > label {
        margin-right: 0;
    }
    form {
        max-height: calc(100vh - 50px); 
    }
}